
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import {
  ICamera, ICameraCurrentImage, ICameraReferenceImage, TCameraType,
} from '@th-common/interfaces/camera';
import dayjs from 'dayjs';

import { api as apiCameraVerification } from './api';

export interface ICameraState {
  items: ICamera[];
  lastTimeUpdated: string;
}

const initialState: ICameraState = {
  items: [],
  lastTimeUpdated: dayjs().toISOString(),
};

export const slice = createSlice({
  name: 'cameraVerification',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    acceptImage: (state, { payload }: {
      payload: {
        image: ICameraCurrentImage;
        cameraType: TCameraType;
        cameraNumber: number;
      };
    }) => {
      const { image, cameraType, cameraNumber } = payload;
      const camera = state.items.find((c) => c.number === cameraNumber);

      if (!camera) {
        return;
      }

      camera[cameraType === 'day' ? 'dayImage' : 'nightImage'].currentImage = image;
    },
    rejectImage: (state, { payload }: {
      payload: {
        image: ICameraCurrentImage;
        cameraType: TCameraType;
        cameraNumber: number;
      };
    }) => {
      const { image, cameraType, cameraNumber } = payload;
      const camera = state.items.find((c) => c.number === cameraNumber);

      if (!camera) {
        return;
      }

      camera[cameraType === 'day' ? 'dayImage' : 'nightImage'].currentImage = image;
    },
    setAsReference: (state, { payload }: {
      payload: {
        image: ICameraReferenceImage;
        cameraType: TCameraType;
        cameraNumber: number;
      };
    }) => {
      const { image, cameraType, cameraNumber } = payload;
      const camera = state.items.find((c) => c.number === cameraNumber);

      if (!camera) {
        return;
      }

      camera[cameraType === 'day' ? 'dayImage' : 'nightImage'].referenceImage = image;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ICameraState>) => {
    builder
      .addMatcher(apiCameraVerification.endpoints.getCameras.matchFulfilled, (state, { payload }: {
        payload: ICamera[];
      }) => {
        state.items = payload;
        state.lastTimeUpdated = dayjs().toISOString();
      });
  },
});

export default slice.reducer;
