import { markedVideoApiEndpoints } from '@th-common/api/marked-video.api';
import { IMarkedVideo, TMarkedVideoSearchRequest } from '@th-common/interfaces/marked-video';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getMarkedVideos: builder.query<IPagedResult<IMarkedVideo>, TMarkedVideoSearchRequest>(
      markedVideoApiEndpoints.getMarkedVideos,
    ),
  }),
});
