
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { videoDownloadApi } from '@th-common/api/video-download.api';
import { TPlaybackSpeedSettingsValue } from '@th-common/enums/video-playback/playback-speed.enum';
import { TViewLayout } from '@th-common/interfaces/player/player';
import { IVideoAdjustments, IVideoSourceInfo } from '@th-common/interfaces/player/video-source-info';
import { IVideoBookmark } from '@th-common/interfaces/video/bookmark';
import {
  ICameraHlsData, IVideoAvSource, IVideoTrack, IVideoTrackHls,
} from '@th-common/interfaces/video/video-request';
import { StorageUtils } from '@th-common/utils/storage';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { startTime } from './gps-fake-data';

dayjs.extend(isBetween);

export interface IVideoPlaybackState {
  docToken: string;
  deviceName: string;
  cameraAvSources: IVideoAvSource[];
  audioAvSources: IVideoAvSource[];
  tracks: IVideoTrackHls[];
  cameraHlsDataByTrack: ICameraHlsData[][];
  eventJSONList: string[];
  currentTrackIndex: number | null;
  cameras: IVideoSourceInfo[];
  audioSources: IVideoSourceInfo[];
  videoRequestStartDayjs: Dayjs;
  videoRequestEndDayjs: Dayjs;
  videoRequestMin: number;
  videoRequestMax: number;
  dirHandle: FileSystemDirectoryHandle | null;
  //
  currentPlayerTime: number;
  currentMasterPlayerDuration: number;
  currentTimelineValue: number;
  currentTimelineDayjs: Dayjs;
  clipValue: [number, number];
  clipDateTime: [string, string];
  videoDownloadId: number;
  maxFps: number;
  bookmarks: IVideoBookmark[];
  activeBookmarkIndex: number | null;
  viewLayout: TViewLayout;
  isMetaDataPanelOpen: boolean;
  playersReady: boolean;
  // Player
  showClip: boolean;
  hoveredFrame: number | null;
  hoveredFrameTooltip: string;
  hoveredFramePositionX: number;
  scaleValue: [number, number];
  playingForward: boolean;
  playingBackward: boolean;
  playbackSpeed: TPlaybackSpeedSettingsValue;
  fromFolder: boolean;
  bigCameraNumber: number | null;
}

const initialState: IVideoPlaybackState = {
  docToken: '',
  deviceName: '',
  cameraAvSources: [],
  audioAvSources: [],
  tracks: [],
  cameraHlsDataByTrack: [],
  eventJSONList: [],
  currentTrackIndex: 0,
  cameras: [],
  audioSources: [],
  dirHandle: null,
  currentPlayerTime: 0,
  currentMasterPlayerDuration: 0,
  currentTimelineValue: 0,
  currentTimelineDayjs: dayjs(),
  clipValue: [0, 0],
  clipDateTime: ['', ''],
  videoDownloadId: 123,
  videoRequestStartDayjs: dayjs(),
  videoRequestEndDayjs: dayjs(),
  videoRequestMin: 0,
  videoRequestMax: 0,
  maxFps: 15,
  bookmarks: [],
  activeBookmarkIndex: null,
  viewLayout: TViewLayout.Grid,
  isMetaDataPanelOpen: false,
  playersReady: false,
  // Player
  showClip: false,
  hoveredFrame: null,
  hoveredFrameTooltip: '',
  hoveredFramePositionX: 0,
  scaleValue: [0, 0],
  playingForward: false,
  playingBackward: false,
  playbackSpeed: TPlaybackSpeedSettingsValue.Speed1,
  fromFolder: false,
  bigCameraNumber: null,
};

const getCurrentTimelineDayjs = (videoRequestStartDayjs: Dayjs, currentTimelineValue: number): Dayjs => {
  return videoRequestStartDayjs.add(currentTimelineValue, 'ms');
};

const getTrackIndexByTimelineDayjs = (
  currentTimelineDayjs: Dayjs,
  tracks: IVideoTrackHls[],
  currentTrackIndex: number | null,
): number => {
  if (currentTrackIndex === null || (
    currentTimelineDayjs.isBefore(tracks[currentTrackIndex].trackStartDayjs)
      || currentTimelineDayjs.isAfter(tracks[currentTrackIndex].trackEndDayjs)
  )) {
    return tracks.findIndex((track) => {
      return currentTimelineDayjs.isBetween(track.trackStartDayjs, track.trackEndDayjs);
    });
  }

  return currentTrackIndex;
};

const getTimelineValueByPlayerTime = (
  playerTimeInMs: number,
  trackStartDayjs: Dayjs,
  videoRequestStartDayjs: Dayjs,
): number => {
  return trackStartDayjs.add(playerTimeInMs, 'ms').diff(videoRequestStartDayjs, 'ms');
};

const getZoomDiffRelatedToHoveredFrame = (
  zoomDiff: number,
  hoveredFrame: number,
  scaleValue: [number, number],
): [number, number] => {
  const hoveredFrameToStart = hoveredFrame - scaleValue[0];
  const hoveredFrameToEnd = scaleValue[1] - hoveredFrame;

  const scaleDiff = scaleValue[1] - scaleValue[0];

  const zoomStartDiff = zoomDiff * (hoveredFrameToStart / scaleDiff);
  const zoomEndDiff = zoomDiff * (hoveredFrameToEnd / scaleDiff);

  return [zoomStartDiff, zoomEndDiff];
};

export const slice = createSlice({
  name: 'videoPlayback',
  initialState,
  reducers: {
    setVideoDownloadId: (state, { payload }: { payload: number }) => {
      state.videoDownloadId = payload;
    },
    setCurrentTimelineValueMs: (state, { payload }: { payload: number }) => {
      const currentTimelineDayjs = getCurrentTimelineDayjs(state.videoRequestStartDayjs, payload);
      const trackIndex = getTrackIndexByTimelineDayjs(
        currentTimelineDayjs,
        state.tracks,
        state.currentTrackIndex,
      );

      if (trackIndex === -1) {
        state.playingForward = false;
        state.playingBackward = false;
        state.currentTrackIndex = null;
        state.currentPlayerTime = 0;
      } else if (trackIndex !== state.currentTrackIndex) {
        state.playersReady = false;
        state.currentTrackIndex = trackIndex;
        state.currentPlayerTime = currentTimelineDayjs.diff(state.tracks[trackIndex].trackStartDayjs, 'ms') / 1000;
      } else {
        state.currentPlayerTime = currentTimelineDayjs.diff(state.tracks[trackIndex].trackStartDayjs, 'ms') / 1000;
      }

      state.currentTimelineValue = payload;
      state.currentTimelineDayjs = currentTimelineDayjs;
    },
    setCurrentPlayerTime: (state, { payload }: { payload: number }) => {
      if (state.currentTrackIndex === null) {
        return;
      }

      const currentPlayerTimeToTimelineValue = getTimelineValueByPlayerTime(
        payload,
        state.tracks[state.currentTrackIndex].trackStartDayjs,
        state.videoRequestStartDayjs,
      );
      const currentTimelineDayjs = getCurrentTimelineDayjs(state.videoRequestStartDayjs, currentPlayerTimeToTimelineValue);

      if (state.playingForward && currentPlayerTimeToTimelineValue > state.videoRequestMax) {
        state.currentTimelineValue = state.videoRequestMax;
        state.currentPlayerTime = state.videoRequestEndDayjs.diff(state.tracks[state.currentTrackIndex].trackStartDayjs, 'ms') / 1000;
        state.currentTimelineDayjs = state.videoRequestEndDayjs;
        state.playingForward = false;
        return;
      }

      if (state.playingBackward && currentPlayerTimeToTimelineValue <= state.videoRequestMin) {
        state.currentPlayerTime = 0;
        state.currentTimelineValue = state.videoRequestMin;
        state.currentTimelineDayjs = state.videoRequestStartDayjs;
        state.playingBackward = false;
        return;
      }

      state.currentTimelineValue = currentPlayerTimeToTimelineValue;
      state.currentTimelineDayjs = currentTimelineDayjs;
    },
    stepFrameForward: (state) => {
      if (state.currentTrackIndex === null) {
        return;
      }

      const currentTimelineDayjs = state.currentTimelineDayjs.add(1 / state.maxFps, 'second');
      const currentPlayerTime = currentTimelineDayjs.diff(state.tracks[state.currentTrackIndex].trackStartDayjs, 'ms') / 1000;

      if (currentPlayerTime > state.currentMasterPlayerDuration) {
        if (state.currentTrackIndex < state.tracks.length - 1) {
          const newTrackIndex = state.currentTrackIndex + 1;
          state.playersReady = false;
          state.currentTrackIndex = newTrackIndex;
          state.currentPlayerTime = 0;
          state.currentTimelineValue = state.tracks[newTrackIndex].trackStartDayjs.diff(state.videoRequestStartDayjs, 'ms');
          state.currentTimelineDayjs = state.tracks[newTrackIndex].trackStartDayjs;
        }
        return;
      }

      state.currentPlayerTime = currentPlayerTime;
      state.currentTimelineValue = currentTimelineDayjs.diff(state.videoRequestStartDayjs, 'ms');
      state.currentTimelineDayjs = currentTimelineDayjs;
    },
    stepFrameBackward: (state) => {
      if (state.currentTrackIndex === null) {
        return;
      }

      const currentTrackStartDayjs = state.tracks[state.currentTrackIndex].trackStartDayjs;
      const currentTimelineDayjs = state.currentTimelineDayjs.subtract(1 / state.maxFps, 'second');
      const currentPlayerTime = currentTimelineDayjs.diff(currentTrackStartDayjs, 'ms') / 1000;

      if (currentPlayerTime < 0) {
        if (state.currentTrackIndex > 0) {
          const newTrackIndex = state.currentTrackIndex - 1;
          state.playersReady = false;
          state.currentTrackIndex = newTrackIndex;
          state.currentPlayerTime = state.tracks[newTrackIndex].trackEndDayjs.diff(state.tracks[newTrackIndex].trackStartDayjs, 'ms') / 1000;
          state.currentTimelineValue = state.tracks[newTrackIndex].trackEndDayjs.diff(state.videoRequestStartDayjs, 'ms');
          state.currentTimelineDayjs = state.tracks[newTrackIndex].trackEndDayjs;
        }
        return;
      }

      state.currentPlayerTime = currentPlayerTime;
      state.currentTimelineValue = currentTimelineDayjs.diff(state.videoRequestStartDayjs, 'ms');
      state.currentTimelineDayjs = currentTimelineDayjs;
    },
    nextTrack: (state) => {
      if (state.currentTrackIndex === null || state.currentTrackIndex === state.tracks.length - 1) {
        return;
      }

      const newTrackIndex = state.currentTrackIndex + 1;

      const nextTrackStartDayjs = state.tracks[newTrackIndex].trackStartDayjs;
      const nextTrackTimeToTimelineValue = nextTrackStartDayjs.diff(state.videoRequestStartDayjs, 'ms');
      const currentTimelineDayjs = state.videoRequestStartDayjs.add(nextTrackTimeToTimelineValue, 'ms');

      state.playersReady = false;
      state.currentTrackIndex = newTrackIndex;
      state.currentPlayerTime = 0;

      state.currentTimelineValue = nextTrackTimeToTimelineValue;
      state.currentTimelineDayjs = currentTimelineDayjs;
    },
    setCurrentDateTime: (state, { payload }: { payload: Dayjs }) => {
      state.currentTimelineValue = payload.diff(state.videoRequestStartDayjs, 'ms');
    },
    setClipValue: (state, { payload }: { payload: [number, number] }) => {
      state.clipValue = payload;
    },
    setCenterFrame: (state) => {
      const scaleDiff = (state.scaleValue[1] - state.scaleValue[0]) / 2;
      let scaleStart = state.currentTimelineValue - scaleDiff;
      let scaleEnd = state.currentTimelineValue + scaleDiff;

      if (scaleStart < state.videoRequestMin) {
        scaleEnd += (state.videoRequestMin - scaleStart);
      }

      if (scaleEnd > state.videoRequestMax) {
        scaleStart -= (scaleEnd - state.videoRequestMax);
      }

      state.scaleValue = [
        Math.max(scaleStart, state.videoRequestMin),
        Math.min(scaleEnd, state.videoRequestMax),
      ];
    },
    addBookmark: (state) => {
      const bookmark = {
        value: state.videoRequestStartDayjs.clone().add(state.currentTimelineValue, 'ms').toISOString(),
        note: '',
        saved: false,
      };

      if (state.activeBookmarkIndex !== null) {
        const activeBookmark = state.bookmarks[state.activeBookmarkIndex];
        if (activeBookmark.saved) {
          state.bookmarks = [...state.bookmarks, bookmark];
          state.activeBookmarkIndex = state.bookmarks.length - 1;
          return;
        }
        state.bookmarks[state.activeBookmarkIndex] = bookmark;
        return;
      }

      state.bookmarks = [...state.bookmarks, bookmark];
      state.activeBookmarkIndex = state.bookmarks.length - 1;
    },
    saveActiveBookmark: (state, { payload }: { payload: IVideoBookmark }) => {
      if (state.activeBookmarkIndex !== null) {
        state.bookmarks[state.activeBookmarkIndex] = {
          ...payload,
          saved: true,
        };
      }

      state.activeBookmarkIndex = null;
    },
    cancelActiveBookmark: (state) => {
      if (state.activeBookmarkIndex !== null && !state.bookmarks[state.activeBookmarkIndex].saved) {
        state.bookmarks = state.bookmarks.filter((_, index) => index !== state.activeBookmarkIndex);
      }

      state.activeBookmarkIndex = null;
    },
    clearBookmarks: (state) => {
      state.bookmarks = [];
      state.activeBookmarkIndex = null;
    },
    setActiveBookmark: (state, { payload }: { payload: number | null }) => {
      state.activeBookmarkIndex = payload === null ? null : payload;
    },
    setCurrentMasterPlayerDuration: (state, { payload }: { payload: number }) => {
      state.currentMasterPlayerDuration = payload;
    },
    cameraSourcesVisibility: (state, { payload }: { payload: string[] }) => {
      state.playersReady = state.currentTrackIndex === null || payload.length < state.cameras.length;
      state.cameras = state.cameras.map((camera) => {
        return {
          ...camera,
          isPlayerHide: !payload.includes(camera.name),
        };
      });

      if (state.currentTrackIndex !== null) {
        const visibleCameraNumbers = state.cameras.filter((camera) => !camera.isPlayerHide).map((camera) => camera.number);
        state.maxFps = Math.max(
          ...state.cameraHlsDataByTrack[state.currentTrackIndex]
            .filter((camera) => visibleCameraNumbers.includes(camera.number))
            .map((camera) => camera.framerate || 30),
        );
      }
    },
    hidePlayer: (state, { payload }: { payload: string }) => {
      state.cameras = state.cameras.map((camera) => {
        if (camera.name === payload) {
          return {
            ...camera,
            isPlayerHide: true,
          };
        }
        return camera;
      });

      if (state.currentTrackIndex !== null) {
        const visibleCameraNumbers = state.cameras.filter((camera) => !camera.isPlayerHide).map((camera) => camera.number);
        state.maxFps = Math.max(
          ...state.cameraHlsDataByTrack[state.currentTrackIndex]
            .filter((camera) => visibleCameraNumbers.includes(camera.number))
            .map((camera) => camera.framerate || 30),
        );
      }
    },
    showPlayer: (state, { payload }: { payload: string }) => {
      state.cameras = state.cameras.map((camera) => {
        if (camera.name === payload) {
          return {
            ...camera,
            isPlayerHide: false,
          };
        }
        return camera;
      });
    },
    setViewLayout: (state, { payload }: { payload: TViewLayout }) => {
      state.viewLayout = payload;
    },
    toggleMetaDataPanel: (state, { payload }: { payload: boolean }) => {
      state.isMetaDataPanelOpen = payload;
    },
    setVideoAdjustments: (state, { payload }: { payload: { cameraNumber: number; videoParams: IVideoAdjustments } }) => {
      const cameraInfo = state.cameras.find((camera) => camera.number === payload.cameraNumber);
      if (cameraInfo) {
        cameraInfo.brightness = payload.videoParams.brightness;
        cameraInfo.contrast = payload.videoParams.contrast;
        cameraInfo.saturate = payload.videoParams.saturate;
      }
    },
    setVideoSource: (state, { payload }: { payload: { file: File; durationInSeconds: number } }) => {
      const videoRequestStartDayjs = startTime;
      const videoRequestEndDayjs = startTime.add(payload.durationInSeconds, 'second');
      const videoRequestMin = 0;
      const videoRequestMax = videoRequestEndDayjs.diff(videoRequestStartDayjs, 'ms');
      const cameraAvSources: IVideoAvSource[] = [
        {
          name: 'File Source',
          type: 'IPCamera',
          number: 1,
        },
      ];
      state.fromFolder = true;
      state.currentTimelineValue = 0;
      state.currentTimelineDayjs = videoRequestStartDayjs;
      state.scaleValue = [videoRequestMin, videoRequestMax];
      state.videoRequestMin = videoRequestMin;
      state.videoRequestMax = videoRequestMax;
      state.deviceName = 'File Source';
      // state.gpsJSONListByTrack = [];
      state.cameraAvSources = cameraAvSources;
      state.audioAvSources = [];
      state.cameras = cameraAvSources.map((source) => {
        return {
          name: source.name,
          number: source.number,
          isPlayerHide: false,
          isVideoAdjustmentsOpen: false,
          brightness: 1,
          contrast: 1,
          saturate: 1,
        };
      });
      state.tracks = [
        {
          start: startTime.toISOString(),
          end: startTime.add(payload.durationInSeconds, 'second').toISOString(),
          trackStartDayjs: startTime,
          trackEndDayjs: startTime.add(payload.durationInSeconds, 'second'),
          severity: 1,
        },
      ];
      state.cameraHlsDataByTrack = [
        [
          {
            number: 1,
            src: URL.createObjectURL(payload.file),
            framerate: 15,
          },
        ],
      ];
      state.videoRequestStartDayjs = videoRequestStartDayjs;
      state.videoRequestEndDayjs = videoRequestEndDayjs;
    },
    resetVideoAdjustments: (state, { payload }: { payload: { cameraName: string } }) => {
      const cameraInfo = state.cameras.find((camera) => camera.name === payload.cameraName);
      if (cameraInfo) {
        cameraInfo.brightness = 1;
        cameraInfo.contrast = 1;
        cameraInfo.saturate = 1;
      }
    },
    toggleVideoAdjustmentsPanel: (state, { payload }: { payload: { cameraName: string; isOpen?: boolean } }) => {
      const cameraInfo = state.cameras.find((camera) => camera.name === payload.cameraName);
      if (cameraInfo) {
        if (payload.isOpen !== undefined) {
          cameraInfo.isVideoAdjustmentsOpen = payload.isOpen;
        } else {
          cameraInfo.isVideoAdjustmentsOpen = !cameraInfo.isVideoAdjustmentsOpen;
        }
      }
    },
    // Player
    toggleClip: (state) => {
      state.showClip = !state.showClip;
    },
    setHoveredFrame: (state, { payload }: { payload: { hoveredFrame: number | null; timeFormat: string; x: number | null } }) => {
      if (payload.x === state.hoveredFramePositionX) {
        return;
      }

      if (payload.x === null) {
        payload.x = state.hoveredFramePositionX;
      }

      state.hoveredFramePositionX = payload.x;

      if (payload.hoveredFrame === state.hoveredFrame) {
        return;
      }

      if (payload.hoveredFrame === null) {
        state.hoveredFrame = null;
        state.hoveredFrameTooltip = '';
        return;
      }

      state.hoveredFrame = payload.hoveredFrame;
      state.hoveredFrameTooltip = state.videoRequestStartDayjs.add(payload.hoveredFrame, 'ms').format(payload.timeFormat);
    },
    setScaleValue: (state, { payload }: { payload: [number, number] }) => {
      const newScaleRange = payload;
      const rangeDiff = newScaleRange[1] - newScaleRange[0];
      const oneMinuteInMs = 60 * 1000;

      if (rangeDiff < oneMinuteInMs) {
        return;
      }

      state.scaleValue = [
        Math.max(payload[0], 0),
        Math.min(payload[1], state.videoRequestEndDayjs.diff(state.videoRequestStartDayjs, 'ms')),
      ];
    },
    playingForward: (state) => {
      state.playingForward = true;
      state.playingBackward = false;
    },
    playingBackward: (state) => {
      state.playingForward = false;
      state.playingBackward = true;
    },
    pauseVideo: (state) => {
      state.playingForward = false;
      state.playingBackward = false;
    },
    setPlaybackSpeed: (state, { payload }: { payload: TPlaybackSpeedSettingsValue }) => {
      state.playbackSpeed = payload;
    },
    skipSegmentToEnd: (state) => {
      const trackIndex = state.tracks.findIndex(track => state.currentTimelineDayjs.isBefore(track.trackEndDayjs));
      if (trackIndex !== -1) {
        const trackEndDayjs = state.tracks[trackIndex].trackEndDayjs;

        state.currentTrackIndex = trackIndex;
        state.currentTimelineValue = trackEndDayjs.diff(state.videoRequestStartDayjs, 'ms');
        state.currentPlayerTime = trackEndDayjs.diff(state.tracks[trackIndex].trackStartDayjs, 'ms') / 1000;
        state.currentTimelineDayjs = trackEndDayjs;
      }
    },
    skipSegmentToStart: (state) => {
      console.log(state.tracks.slice().reverse());
      let trackIndex = state.tracks.length - 1;

      for (let i = state.tracks.length - 1; i >= 0; i--) {
        if (state.currentTimelineDayjs.isAfter(state.tracks[i].trackStartDayjs)) {
          trackIndex = i;
          break;
        }
      }
      if (trackIndex !== -1) {
        const trackStartDayjs = state.tracks[trackIndex].trackStartDayjs;

        state.currentTrackIndex = trackIndex;
        state.currentTimelineValue = trackStartDayjs.diff(state.videoRequestStartDayjs, 'ms');
        state.currentPlayerTime = trackStartDayjs.diff(state.tracks[trackIndex].trackStartDayjs, 'ms') / 1000;
        state.currentTimelineDayjs = trackStartDayjs;
      }
    },
    zoomIn: (state) => {
      let zoomStartDiff = (state.scaleValue[1] - state.scaleValue[0]) * 0.02;
      let zoomEndDiff = (state.scaleValue[1] - state.scaleValue[0]) * 0.02;

      if (state.hoveredFrame !== null) {
        [
          zoomStartDiff,
          zoomEndDiff,
        ] = getZoomDiffRelatedToHoveredFrame(zoomStartDiff, state.hoveredFrame, state.scaleValue);
      }

      const scaleMinValue = state.scaleValue[0] + zoomStartDiff;
      const scaleMaxValue = state.scaleValue[1] - zoomEndDiff;

      state.scaleValue = [
        Math.min(scaleMinValue, scaleMaxValue),
        Math.max(scaleMaxValue, scaleMinValue),
      ];
    },
    zoomOut: (state) => {
      let zoomStartDiff = (state.scaleValue[1] - state.scaleValue[0]) * 0.02;
      let zoomEndDiff = (state.scaleValue[1] - state.scaleValue[0]) * 0.02;

      if (state.hoveredFrame !== null) {
        [
          zoomStartDiff,
          zoomEndDiff,
        ] = getZoomDiffRelatedToHoveredFrame(zoomStartDiff, state.hoveredFrame, state.scaleValue);
      }

      state.scaleValue = [
        Math.max(state.scaleValue[0] - zoomStartDiff, state.videoRequestMin),
        Math.min(state.scaleValue[1] + zoomEndDiff, state.videoRequestMax),
      ];
    },
    setBigCameraNumber: (state, { payload }: { payload: number | null }) => {
      state.bigCameraNumber = state.bigCameraNumber === payload ? null : payload;
    },
    setTrackCameraFramerate: (state, { payload }: { payload: { cameraNumber: number; framerate: number } }) => {
      if (state.currentTrackIndex === null) {
        return;
      }

      state.cameraHlsDataByTrack[state.currentTrackIndex] = state.cameraHlsDataByTrack[state.currentTrackIndex].map((camera) => {
        if (camera.number === payload.cameraNumber) {
          return {
            ...camera,
            framerate: payload.framerate,
          };
        }
        return camera;
      });
      state.maxFps = Math.max(...state.cameraHlsDataByTrack[state.currentTrackIndex].map((camera) => camera.framerate || 0));
    },
    playersReady: (state) => {
      state.playersReady = true;
    },
    reset: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoPlaybackState>) => {
    builder
      .addMatcher(videoDownloadApi.endpoints.getToken.matchFulfilled, (state, { payload }) => {
        state.docToken = payload.token;
      }).addMatcher(videoDownloadApi.endpoints.getVideoDownload.matchFulfilled, (state, { payload }) => {
        const cameraAvSources = payload.avSources.filter((source) => source.type === 'IPCamera');
        const apiBaseUrl = (StorageUtils.search('env', 'sessionStorage') as { apiBaseUrl: string }).apiBaseUrl;
        const videoRequestStartDayjs = dayjs.parseZone(payload.start);
        const videoRequestEndDayjs = dayjs.parseZone(payload.end);
        const videoRequestMin = 0;
        const videoRequestMax = videoRequestEndDayjs.diff(videoRequestStartDayjs, 'ms');

        state.currentTimelineValue = 0;
        state.currentTimelineDayjs = videoRequestStartDayjs;
        state.scaleValue = [videoRequestMin, videoRequestMax];
        state.videoRequestMin = videoRequestMin;
        state.videoRequestMax = videoRequestMax;
        state.deviceName = payload.device;
        state.cameraAvSources = cameraAvSources;
        state.audioAvSources = payload.avSources.filter((source) => source.type === 'IPAudio');
        state.cameras = cameraAvSources.map((source) => {
          return {
            name: source.name,
            number: source.number,
            isPlayerHide: false,
            isVideoAdjustmentsOpen: false,
            brightness: 1,
            contrast: 1,
            saturate: 1,
          };
        });
        const {
          tracks,
          cameraHlsDataByTrack,
        } = payload.tracks.reduce((acc, track: IVideoTrack) => {
          const trackStartDayjs = dayjs.parseZone(track.start);
          const trackEndDayjs = dayjs.parseZone(track.end);

          acc.tracks.push({
            start: track.start,
            end: track.end,
            trackStartDayjs,
            trackEndDayjs,
            severity: track.severity,
          } as IVideoTrackHls);

          acc.cameraHlsDataByTrack.push(track.avSources.map((avSource) => {
            return {
              number: avSource.avSource,
              src: `${apiBaseUrl}/video-download/${state.videoDownloadId}/${avSource.playlist}`,
              framerate: null,
            };
          }));

          return acc;
        }, {
          tracks: [] as IVideoTrackHls[],
          cameraHlsDataByTrack: [] as ICameraHlsData[][],
        });
        state.tracks = tracks;
        state.cameraHlsDataByTrack = cameraHlsDataByTrack;
        state.eventJSONList = payload.event || [];
        state.videoRequestStartDayjs = videoRequestStartDayjs;
        state.videoRequestEndDayjs = videoRequestEndDayjs;
        state.bookmarks = [];
        state.activeBookmarkIndex = null;
      });
  },
});

export default slice.reducer;
