import { TMenuItem } from '@th-common/interfaces/navigation-menu';

export enum TPlaybackSpeedSettings {
  Speed02 = '0.2x',
  Speed05 = '0.5x',
  Speed07 = '0.7x',
  Speed1 = '1x',
  Speed12 = '1.2x',
  Speed15 = '1.5x',
  Speed2 = '2x',
}

export enum TPlaybackSpeedSettingsValue {
  Speed02 = 0.2,
  Speed05 = 0.5,
  Speed07 = 0.7,
  Speed1 = 1,
  Speed12 = 1.2,
  Speed15 = 1.5,
  Speed2 = 2,
}

export const playbackSpeedToMenu: TMenuItem[] = [
  {
    title: TPlaybackSpeedSettings.Speed02,
    value: TPlaybackSpeedSettingsValue.Speed02,
  },
  {
    title: TPlaybackSpeedSettings.Speed05,
    value: TPlaybackSpeedSettingsValue.Speed05,
  },
  {
    title: TPlaybackSpeedSettings.Speed07,
    value: TPlaybackSpeedSettingsValue.Speed07,
  },
  {
    title: TPlaybackSpeedSettings.Speed1,
    value: TPlaybackSpeedSettingsValue.Speed1,
  },
  {
    title: TPlaybackSpeedSettings.Speed12,
    value: TPlaybackSpeedSettingsValue.Speed12,
  },
  {
    title: TPlaybackSpeedSettings.Speed15,
    value: TPlaybackSpeedSettingsValue.Speed15,
  },
  {
    title: TPlaybackSpeedSettings.Speed2,
    value: TPlaybackSpeedSettingsValue.Speed2,
  },
];

export const playbackSpeedValueToIcon = {
  [TPlaybackSpeedSettingsValue.Speed02]: 'speed-0-2x',
  [TPlaybackSpeedSettingsValue.Speed05]: 'speed-0-5x',
  [TPlaybackSpeedSettingsValue.Speed07]: 'speed-0-7x',
  [TPlaybackSpeedSettingsValue.Speed1]: 'speed-1x',
  [TPlaybackSpeedSettingsValue.Speed12]: 'speed-1-2x',
  [TPlaybackSpeedSettingsValue.Speed15]: 'speed-1-5x',
  [TPlaybackSpeedSettingsValue.Speed2]: 'speed-2x',
};

export const playbackSpeedList = [
  TPlaybackSpeedSettings.Speed02,
  TPlaybackSpeedSettings.Speed05,
  TPlaybackSpeedSettings.Speed07,
  TPlaybackSpeedSettings.Speed1,
  TPlaybackSpeedSettings.Speed12,
  TPlaybackSpeedSettings.Speed15,
  TPlaybackSpeedSettings.Speed2,
];
