import { ILoginConfig, IServerConfig } from '@th-common/interfaces/system';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    loginConfig: builder.query<ILoginConfig, void>({
      query: () => 'system/login-config',
    }),
    serverConfig: builder.query<IServerConfig, void>({
      query: () => 'system/server-config',
    }),
    getApiBuildNumber: builder.query<string, void>({
      query: () => 'system/build-number',
    }),
  }),
});
