
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { dateKindToDate } from '@th-common/components/form/DateKindPicker/index.helpers';
import { TDateKind } from '@th-common/enums/date-kind.enum';
import {
  ILogbook,
  ILogbookFilters,
  ILogbookFiltersFormValues,
  ILogbookSearchRequest,
} from '@th-common/interfaces/logbook';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { DateTimeUtils } from '@th-common/utils/dateTime';
import dayjs, { Dayjs } from 'dayjs';

import { api as apiLogbook } from './api';

function convertFormValuesDateToSearchRequestDate({
  fromDate,
  toDate,
}: Pick<ILogbookFiltersFormValues, 'fromDate' | 'toDate'>): Pick<ILogbookFilters, 'fromDate' | 'toDate'> {
  return {
    fromDate: DateTimeUtils.onlyDateRequest(fromDate),
    toDate: toDate.toISOString(),
  };
}

function convertFormValueToSearchRequestFilters(formValues: ILogbookFiltersFormValues): ILogbookFilters {
  return {
    logLevel: formValues.logLevel === 'All' ? null : formValues.logLevel,
    ...convertFormValuesDateToSearchRequestDate(formValues),
  };
}

const [defaultFromDate, defaultToDate] = dateKindToDate(TDateKind.Recent);

export const defaultFilters: ILogbookFilters = {
  logLevel: null,
  fromDate: DateTimeUtils.onlyDateRequest(defaultFromDate),
  toDate: defaultToDate.toISOString(),
};

interface ISetFilterDatePayload {
  payload: Pick<ILogbookFiltersFormValues, 'fromDate' | 'toDate'>;
}

export interface ILogbookState {
  items: ILogbook[];
  pagination: Omit<IPagedResult<ILogbook>, 'items'>;
  searchRequest: ILogbookSearchRequest;
  filterDateKind: TDateKind;
  lastTimeUpdated: string;
  isLogbookTabOpened: boolean;
}

const initialState: ILogbookState = {
  items: [],
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  searchRequest: {
    page: 1,
    pageSize: 50,
    searches: [],
    searchAsOneWord: true,
    orderBy: [
      {
        fieldName: 'timestamp',
        ascending: false,
      },
    ],
    ...defaultFilters,
  },
  filterDateKind: TDateKind.ForDate,
  lastTimeUpdated: dayjs().toISOString(),
  isLogbookTabOpened: false,
};

export const slice = createSlice({
  name: 'logbook',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    formFilter: (state, { payload }) => {
      const { dateKind, ...filters } = payload;

      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        ...convertFormValueToSearchRequestFilters(filters),
      };
      state.filterDateKind = dateKind;
    },
    setFilterDate: (state, { payload }: ISetFilterDatePayload) => {
      const { fromDate, toDate } = convertFormValuesDateToSearchRequestDate(payload);

      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        fromDate,
        toDate,
      };
    },
    setInitialToDate: (state, { payload }: { payload: Dayjs }) => {
      state.searchRequest.toDate = payload.toISOString();
    },
    setFilterDateKind: (state, { payload }: { payload: TDateKind }) => {
      state.filterDateKind = payload;
    },
    setPage: (state, { payload }: { payload: number }) => {
      state.searchRequest.page = payload;
    },
    setLogbookTabOpened: (state, { payload }: { payload: boolean }) => {
      state.isLogbookTabOpened = payload;
    },
    search: (state, { payload }: { payload: string }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        searches: payload ? [payload] : [],
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ILogbookState>) => {
    builder.addMatcher(apiLogbook.endpoints.getLogbook.matchFulfilled, (state, { payload }) => {
      const { items, ...pagination } = payload;

      state.items = items;
      state.pagination = pagination;
      state.lastTimeUpdated = dayjs().toISOString();
    });
  },
});

export default slice.reducer;
