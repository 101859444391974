/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiIconButton: Components<Omit<Theme, 'components'>>['MuiIconButton'] = {
  styleOverrides: {
    root: {
      margin: 0,
      borderRadius: '0.5rem',
      '&:hover': {
        backgroundColor: 'var(--color-surf-container-highest)',
      },
      color: 'var(--color-on-surf-variant)',
      '&.Mui-disabled': {
        color: 'var(--color-outline)',
      },
    },
  },
};
