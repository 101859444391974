
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { dateKindToDate } from '@th-common/components/form/DateKindPicker/index.helpers';
import { TOrderByDirection } from '@th-common/components/material/MatTable/types/table-order-by';
import { TDateKind } from '@th-common/enums/date-kind.enum';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import {
  IStatusHistory,
  IStatusHistoryFilters,
  IStatusHistoryFiltersFormValues,
  IStatusHistorySearchRequest,
} from '@th-common/interfaces/status-history';
import { DateTimeUtils } from '@th-common/utils/dateTime';
import dayjs from 'dayjs';

import { api as apiStatusHistory } from './api';

export interface IStatusHistoryState {
  items: IStatusHistory[];
  pagination: Omit<IPagedResult<IStatusHistory>, 'items'>;
  searchRequest: IStatusHistorySearchRequest;
  filterDateKind: TDateKind;
  lastTimeUpdated: string;
}

const [defaultFromDate, defaultToDate] = dateKindToDate(TDateKind.Recent);

interface ISetFilterDatePayload {
  payload: Pick<IStatusHistoryFiltersFormValues, 'fromDate' | 'toDate'>;
}

function convertFormValuesDateToSearchRequestDate(
  { fromDate, toDate }: Pick<IStatusHistoryFiltersFormValues, 'fromDate' | 'toDate'>,
): Pick<IStatusHistoryFilters, 'fromDate' | 'toDate'> {
  return {
    fromDate: DateTimeUtils.onlyDateRequest(fromDate),
    toDate: DateTimeUtils.onlyDateRequest(toDate),
  };
}

function convertFormValueToSearchRequestFilters(formValues: IStatusHistoryFiltersFormValues): IStatusHistoryFilters {
  return {
    overallStatus: formValues.overallStatus === 'All' ? null : formValues.overallStatus,
    ...convertFormValuesDateToSearchRequestDate(formValues),
  };
}

export const defaultFilters: IStatusHistoryFilters = {
  overallStatus: null,
  ...convertFormValuesDateToSearchRequestDate({
    fromDate: defaultFromDate,
    toDate: defaultToDate,
  }),
};

const initialState: IStatusHistoryState = {
  items: [],
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  searchRequest: {
    page: 1,
    pageSize: 24,
    searches: [],
    searchAsOneWord: true,
    orderBy: [
      {
        fieldName: 'timestamp',
        ascending: false,
      },
    ],
    ...defaultFilters,
  },
  filterDateKind: TDateKind.Recent,
  lastTimeUpdated: dayjs().toISOString(),
};

export const slice = createSlice({
  name: 'statusHistory',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    formFilter: (state, { payload }) => {
      const { dateKind, ...filters } = payload;

      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        ...convertFormValueToSearchRequestFilters(filters),
      };
      state.filterDateKind = dateKind || state.filterDateKind;
    },
    setFilterDate: (state, { payload }: ISetFilterDatePayload) => {
      const { fromDate, toDate } = convertFormValuesDateToSearchRequestDate(payload);

      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        fromDate,
        toDate,
      };
    },
    setFilterDateKind: (state, { payload }: { payload: TDateKind }) => {
      state.filterDateKind = payload;
    },
    setPage: (state, { payload }) => {
      state.searchRequest.page = payload;
    },
    setOrderBy: (state, { payload: [fieldName, direction] }: { payload: [string, TOrderByDirection] }) => {
      state.searchRequest = {
        ...state.searchRequest,
        orderBy: state.searchRequest.orderBy.length === 0 || direction ? [
          {
            fieldName,
            ascending: direction === 'asc',
          },
        ] : [],
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IStatusHistoryState>) => {
    builder
      .addMatcher(apiStatusHistory.endpoints.getStatusHistory.matchFulfilled, (state, { payload }) => {
        const { items, ...pagination } = payload;

        state.items = items;
        state.pagination = pagination;
        state.lastTimeUpdated = dayjs().toISOString();
      });
  },
});

export default slice.reducer;
