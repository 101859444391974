
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import {
  IDeviceInfo,
  IGeographicalQueryDeviceExecution,
  IGeographicalQueryDeviceExecutionInfo,
} from '@th-common/interfaces/geographical-query-job';
import dayjs from 'dayjs';

import { geographicalQueryDetailsApi } from './api';

export interface IGeographicalQueryDetailsState {
  deviceInfos: IDeviceInfo[];
  lastTimeUpdated: string;
  selectGeographicalQueryExecution: IGeographicalQueryDeviceExecution | null;
  selectedGeographicalQueryDeviceExecutionInfo: IGeographicalQueryDeviceExecutionInfo | null;
}

const initialState: IGeographicalQueryDetailsState = {
  deviceInfos: [],
  lastTimeUpdated: dayjs().toISOString(),
  selectGeographicalQueryExecution: null,
  selectedGeographicalQueryDeviceExecutionInfo: null,
};

export const slice = createSlice({
  name: 'geographicalQueryDetails',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
      selectGeographicalQueryExecution: null,
    }),
    selectGeographicalQueryExecution: (state, { payload }) => {
      state.selectGeographicalQueryExecution = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IGeographicalQueryDetailsState>) => {
    builder
      .addMatcher(geographicalQueryDetailsApi.endpoints.getGeographicalQueryJob.matchFulfilled, (state, { payload }) => {
        const { deviceInfos } = payload;

        state.deviceInfos = deviceInfos;
        state.lastTimeUpdated = dayjs().toISOString();
      })
      .addMatcher(geographicalQueryDetailsApi.endpoints.getGeographicalQueryDeviceExecutionInfo.matchFulfilled, (state, { payload }) => {
        state.selectedGeographicalQueryDeviceExecutionInfo = payload;
      });
  },
});

export default slice.reducer;
