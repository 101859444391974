import {
  FC, JSX, ReactElement, useEffect, useMemo, useState,
} from 'react';
import Loader from '@th-common/components/common/Loader';
import { StorageUtils } from '@th-common/utils';

import styles from './environment.provider.module.scss';

interface IProps {
  isStorybook?: boolean;
  children: ReactElement<any>;
}

const EnvironmentProvider: FC<IProps> = ({
  isStorybook = false,
  children,
}): JSX.Element => {
  if (isStorybook) {
    return children;
  }

  const isDevMode = process.env.NODE_ENV === 'development';
  const LoaderEl = useMemo(() => <div className={styles.wrapper}><Loader /></div>, []);
  const [result, setResult] = useState(LoaderEl);

  useEffect(() => {
    const envRequest = fetch(isDevMode
      ? `/environments/environment.${process.env.NEXT_PUBLIC_ENVIRONMENT_NAME || 'local'}.json`
      : '/environment.json');

    envRequest
      .then((response) => response.json())
      .then((data) => {
        StorageUtils.set('env', data, 'sessionStorage');
        setResult(children);
      });
  }, []);

  return result;
};

export default EnvironmentProvider;
