import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import { TMarkedVideoSearchRequest } from '@th-common/interfaces/marked-video';
import { apiRoot } from '@th-common/store/api';

export const markedVideoApiEndpoints = {
  getMarkedVideos: {
    query: (searchRequest: TMarkedVideoSearchRequest) => {
      return {
        url: 'marked-videos/all',
        method: 'POST',
        body: searchRequest,
      };
    },
    providesTags: [
      TReduxTag.MarkedVideo,
    ],
  },
  deleteMarkedVideo: {
    query: (id: number) => ({
      url: `marked-videos/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: [
      TReduxTag.MarkedVideo,
    ],
  },
};

export const markedVideoApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    deleteMarkedVideo: builder.mutation<void, number>(markedVideoApiEndpoints.deleteMarkedVideo),
  }),
});
