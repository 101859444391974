import { TLicensedFeature } from '@th-common/enums/licensed-feature.enum';
import { TDeviceTimeoutScale } from '@th-common/enums/system/server-config.enum';
import { RootState } from '@th-common/store/store';

export const selectors = {
  deviceTimeoutToUnkownValue: (state: RootState): number => state.system.serverConfig.deviceTimeoutToUnkownValue,
  deviceTimeoutScale: (state: RootState): TDeviceTimeoutScale => state.system.serverConfig.deviceTimeoutScale,
  licensedFeatures: (state: RootState): TLicensedFeature[] => state.system.serverConfig.licensedFeatures,
  storageThinningEnabled: (state: RootState): boolean => state.system.serverConfig.storageThinningEnabled,
  apiBuildNumber: (state: RootState): string => state.system.apiBuildNumber,
};
