import { TMapProvider } from '@th-common/enums/client-settings/map-provider.enum';
import { TUnitOfSpeed } from '@th-common/enums/client-settings/unit-of-speed.enum';
import { TUnitOfTemperature } from '@th-common/enums/client-settings/unit-of-temperature.enum';
import { IUserProfileClientSettings, IUserProfileGeolocationArea, IUserProfileMe } from '@th-common/interfaces/user-profile';

import { RootState } from '../store';

export const selectors = {
  me: (state: RootState): Omit<IUserProfileMe, 'clientSettings'> => state.userProfile.me,
  clientSettings: (state: RootState): IUserProfileClientSettings => state.userProfile.clientSettings,
  temperatureUnit: (state: RootState): TUnitOfTemperature => state.userProfile.clientSettings.unitOfTemperature,
  speedUnit: (state: RootState): TUnitOfSpeed => state.userProfile.clientSettings.unitOfSpeed,
  use12HourFormat: (state: RootState): boolean => state.userProfile.clientSettings.use12HourFormat,
  mapProvider: (state: RootState): TMapProvider => state.userProfile.clientSettings.mapProvider,
  defaultGeolocationArea: (state: RootState): IUserProfileGeolocationArea | null => state.userProfile.clientSettings.defaultGeolocationArea,
  headerHidden: (state: RootState): boolean => state.userProfile.interfaceSettings.headerHidden,
  headerDrawerEnabled: (state: RootState): boolean => state.userProfile.interfaceSettings.headerDrawerEnabled,
  headerDrawerClosed: (state: RootState): boolean => state.userProfile.interfaceSettings.headerDrawerClosed,
  useGridsInsteadOfCards: (state: RootState): boolean => state.userProfile.clientSettings.useGridsInsteadOfCards,
};
