import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import { TStorageType } from '@th-common/enums/video-playback/storage-type.enum';
import { IVideoArchiveSearchRequest } from '@th-common/interfaces/video-archive.interface';
import { apiRoot } from '@th-common/store/api';

export const videoArchiveApiEndpoints = {
  getVideoArchiveList: {
    query: (searchRequest: IVideoArchiveSearchRequest) => {
      return {
        url: 'videoplayback/video-archive/all',
        method: 'POST',
        body: searchRequest,
      };
    },
    providesTags: [
      TReduxTag.VideoArchiveList,
    ],
  },
  deleteVideoArchive: {
    query: (id: number) => ({
      url: `videoplayback/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: [
      TReduxTag.VideoArchiveList,
    ],
  },
  setCompleted: {
    query: (id: number) => ({
      url: `videoplayback/video-archive-set-complete?command=${id}`,
      method: 'PUT',
      body: {},
    }),
    invalidatesTags: [
      TReduxTag.VideoArchiveList,
    ],
  },
  setStorage: {
    query: (data: { id: number; storageType: TStorageType }) => ({
      url: 'videoplayback/video-archive-storage',
      method: 'PUT',
      body: data,
    }),
    invalidatesTags: [
      TReduxTag.VideoArchiveList,
    ],
  },
};

export const videoArchiveApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    deleteVideoArchive: builder.mutation<void, number>(videoArchiveApiEndpoints.deleteVideoArchive),
    setVideoArchiveCompleted: builder.mutation<void, number>(videoArchiveApiEndpoints.setCompleted),
    setVideoArchiveStorage: builder.mutation<void, { id: number; storageType: TStorageType }>(
      videoArchiveApiEndpoints.setStorage,
    ),
  }),
});
