import {
  BaseQueryApi, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryReturnValue,
} from '@reduxjs/toolkit/query';
import { IVideoFile } from '@th-common/interfaces/video/video-request';
import { apiRoot } from '@th-common/store/api';
import { IBaseQueryExtraOptions } from '@th-common/store/handlers';

interface IVideoDownloadGpsDataParams {
  videoDownloadId: number;
  docToken: string;
  fileNames: string[];
}

const mapFileNamesToAPI = (
  params: IVideoDownloadGpsDataParams,
  type: 'gps' | 'event',
): string[] => {
  return params.fileNames
    .map(
      (fileName) => `video-download/${params.videoDownloadId}/${type}/${fileName}?docToken=${params.docToken}`,
    );
};

const buildQueryFnForFileNames = (
  type: 'gps' | 'event',
) => {
  return async (
    _arg: IVideoDownloadGpsDataParams,
    _queryApi: BaseQueryApi,
    _extraOptions: IBaseQueryExtraOptions,
    fetchWithBQ: (arg: string | FetchArgs) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
  ) => {
    const gpsDataPromise = Promise.all(
      mapFileNamesToAPI(_arg, type).map((fileApiUrl) => fetchWithBQ(fileApiUrl)),
    );
    const result = await gpsDataPromise;
    return result
      ? { data: result.map((res) => res.data).flat() as string[] }
      : {
        error: {
          status: 'CUSTOM_ERROR',
          error: 'No data',
        } as FetchBaseQueryError,
      };
  };
};

export const videoDownloadApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getToken: builder.query<{
      token: string;
      expiresIn: number;
    }, number>({
      query: (videoDownloadId) => `video-download/${videoDownloadId}/token`,
    }),
    getVideoDownload: builder.query<IVideoFile, { videoDownloadId: number; docToken: string }>({
      query: ({
        videoDownloadId,
        docToken,
      }) => {
        return {
          url: `video-download/${videoDownloadId}/index`,
          method: 'GET',
          params: {
            docToken,
          },
        };
      },
    }),
    getVideoDownloadTrackGpsData: builder.query<string[], IVideoDownloadGpsDataParams>({
      queryFn: buildQueryFnForFileNames('gps'),
    }),
  }),
});
