
import { PaletteOptions } from '@mui/material';

import {
  error, neutralVariant, primary, secondary, success, tertiary, warning,
} from './colors';

export const palette: PaletteOptions = {
  ...primary,
  ...secondary,
  ...tertiary,
  ...error,
  ...neutralVariant,
  ...warning,
  ...success,
};
