import { TMenuItem } from '@th-common/interfaces/navigation-menu';

export const gpsTraceRanges: TMenuItem[] = [
  { title: '1 Min', value: 1 },
  { title: '2 Min', value: 2 },
  { title: '3 Min', value: 3 },
  { title: '30 Min', value: 30 },
  { title: '1 Hour', value: 60 },
  { title: '2 Hour', value: 120 },
  { title: '4 Hour', value: 240 },
  { title: '6 Hour', value: 360 },
  { title: '12 Hour', value: 720 },
];
